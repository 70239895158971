import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Box, Heading, Image, Flex, Text, SimpleGrid } from "@chakra-ui/core"

import imgSpitfire from "../images/spitfire.jpg"
import imgShockwave from "../images/shockwave.jpg"
import imgShadow from "../images/shadow.jpg"

const IndexPage = () => {
  const data = 
    [
      {
        name:"Spitfire",
        code:"spitfire",
        description:"Première création du duo de champions Loday / White, le SPitfire permet d'atteindre des performances spectaculaires réservées au Formule 18 jusque là. Il répond aux exigences des meilleurs coureurs et convient parfaitement aux équipages mixtes et légers. Le Spitfire fait preuve d'une qualité de finition irréprochable qui se traduit par une multitude de détail aussi bien innovants que pratiques : des safrans relevables selon 3 positions, un système unique de rotation du mât pour plus de sécurité ou encore un foc latté pour moins d'usure. Ses coques lég§res et la finesse de ses lignes permettent une accélération instantanée et l'atteinte de grandes vitesses. Très précis à la barre, la grande réactivité du Spitfire est impressionnante.",
        img:imgSpitfire
      },
      {
        name:"Shadow",
        code:"shadow",
        description:"Catamaran solitaire, le Shadow est un bateau de grande qualité destiné à la haute eprformance. Parmi les plus rapides catamarans solitaires avec les Classe A, il peut aussi être utilisé en double par un équipage léger pour un usage loisir. Équipé d'une voile Mylar et d'un spi asymétrique, le shadow est un catamaran sportif sur lequel il est étonnement simple de naviguer. Sa construction high tech fait de lui un support très réactif procurant de fortes sensations. Le Shadow se distingue surtout par son incroyable légèreté. Cela fait de lui un catamaran facile à déplacer, à mettre à l'eau et à redresser le cas échéant. Une seconde version du Shadow est disponible : le Shadow X, doté d'un spi plus grand.",
        img:imgShadow
      },
      {
        name:"Shockwave",
        code:"shockwave",
        description:"Dernier né du duo Loday-White et bateau amiral de la gamme, le SHockwave s'inscrit dans la lignée des bateaux haute performance et hi-tech, chers à son architecte Yves Loday. 40 ans de pratique, de design et de développement ont permis de donner naissance à ce concentré d'innovation à la point de la technlogie qui saura satisfaire les meilleurs régatiers. Ses voluments importants, la finesse de ses lignes et ses ponts profilés sur la traverse avant permettent à ce catamaran d'exception de s'inscrire en tête des plus grands événement sportifs mondiaux en Formule 18. Une fois de plus, Loday-White et ses prestigieux partenaires voiliers et accastilleurs ont su se distinguer et proposer un bateau performant, précis et rapide, accessible à tous les régatiers.",
        img:imgShockwave
      }
    ]
  
  return(
  <Layout>
    <SEO
      title="Catamarans pour la performance"
      description="Swell Catamarans est une gamme de catamarans de sport tournés vers la performance. Designé par les deux champions Yves Loday et Reg White, les productions Swell sont léchées et proposent un finition d'une très grande qualité."
      image={`https://www.swell-catamarans${imgSpitfire}`}
    />
    { data.map( boat => 
    
    <Box id={boat.code} as="section" borderBottom="solid 1px" borderColor="#F1F1F1">
      <SimpleGrid columns={{ xs: 1, lg: 2 }}>
        <Flex alignItems="center">
          <Box margin={{ xs:"1rem", lg:"4rem"}}>
            <Heading mb="1.5rem">{boat.name}</Heading>
            <Box>
              <Text>
                {boat.description}
              </Text>
            </Box>
          </Box>
        </Flex>
        <Box bg='gray.50' p="5rem">
          <Image m="0" h="100%" w="100%" objectFit="cover" src={boat.img} alt={boat.name} />
        </Box>
      </SimpleGrid>
    </Box>

    )}
    
  </Layout>
  )

    }

export default IndexPage
